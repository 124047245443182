<template>
    <div class="container my-5">
        <div class="d-flex flex-column align-items-center">
            <h4>Forgot Password</h4>
            <form @submit="handleSubmit" v-if="!submittedForm">
                <div class="form-group">
                    <div class="my-2">Enter the email address of your account.</div>
                    <input
                        type="email"
                        v-model="email"
                        placeholder="enter email"
                        class="form-control"
                    />
                </div>
                <button class="btn">{{ processingForm ? "Please wait..." : "Submit" }}</button>
            </form>
            <div v-else>
                <div class="text-center my-3">
                    We have sent a password reset link to your email address.
                    <br />Please check your inbox for further instructions.
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../apis/Api"
import request from '../../apis/request'

export default {
    data() {
        return {
            processingForm: false,
            submittedForm: false,
            email: "",
            emailRegex: /([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|"([]!#-[^-~ \t]|(\\[\t -~]))+")@[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?(\.[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?)+/,
        }
    },
    methods: {
        async handleSubmit(e) {
            e.preventDefault()
            if (!this.verifyInputs()) return
            if (this.processingForm) return
            this.processingForm = true
            try {
                const res = await request.post("/forgot-password-link", {
                    email: this.email,
                })
                this.$toasted.success(res.data.message || "Password reset link sent.")
                this.submittedForm = true
            } catch (err) {
                this.$toasted.error(
                    (err.response && err.response.data && err.response.data.message) ||
                        "Could not send password reset link."
                )
            }
            this.processingForm = false
        },
        verifyInputs() {
            if (!this.emailRegex.test(this.email)) {
                this.$toasted.error("Please enter a valid email.")
                return false
            }
            return true
        },
    },
}
</script>

<style></style>
